import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Col, Container, Row } from "react-bootstrap"
import TitleChecked from "../components/TitleChecked"
import { PageContainer } from "../components/PageContainer/styles";
import BannerInfo from "../components/BannerInfo";
import { theme } from "../theme"

const IndexPage = () => {

  return (<Layout>
    <SEO 
      title="Servizi"
      description="Impresa Raf Service è un'impresa di pulizie e servizi per l'igiene ambientale con sede a Milano. RAF SERVICE esegue anche la cura di aree verdi interne ed esterne, la manutenzione straordinaria di campi da calcio in erba e sintetici, l'installazione di impianti di irrigazione a scomparsa."
    />
    <PageContainer>
      <Container>
        <Row>
          <Col xs={12} style={{ marginBottom: theme.spacing.betweenRows }}>
            <TitleChecked title="Competenza e serietà nello sviluppo del lavoro con personale affidabile e discreto" />
            <p style={{ marginBottom: '20px' }}>Avvelendosi di personale selezionato e qualificato, <strong>RAF SERVICE</strong> opera con l'utilizzo di macchinari di ultima generazione in pulizie e manutenzione di:</p>
            <Row>
              <Col md={6}>
                <ul>
                  <li><strong>Condomini</strong></li>
                  <li><strong>Uffici pubblici e privati</strong></li>
                  <li><strong>Magazzini</strong></li>
                  <li><strong>Ipermercati</strong></li>
                  <li><strong>Supermercati</strong></li>
                </ul>
              </Col>
              <Col md={6}>
                <ul>
                  <li><strong>Centri commerciali</strong></li>
                  <li><strong>Sale cinematografiche</strong></li>
                  <li><strong>Teatri</strong></li>
                  <li><strong>Alberghi</strong></li>
                  <li><strong>Scuole</strong></li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={12} style={{ marginBottom: theme.spacing.betweenRows }}>
            <TitleChecked title="Aree verdi, campi da calcio, impianti di irrigazione" />
            <p style={{ marginBottom: '20px' }}>Oltre ai servizi classici di sanificazione di ambienti, <strong>RAF SERVICE</strong> offre anche:</p>
            <BannerInfo>
              <ul>
                <li>la cura di <strong>aree verdi</strong> interne ed esterne</li>
                <li>la manutenzione straordinaria di <strong>campi da calcio</strong> in erba e sintetici</li>
                <li>l'installazione di <strong>impianti di irrigazione</strong> a scomparsa</li>
              </ul>
            </BannerInfo>
          </Col>
        </Row>
        <Row>
          <Col xs={12} style={{ marginBottom: theme.spacing.betweenRows }}>
            <TitleChecked title="Qualità e rispetto delle regole: i valori aggiunti" />
            <ul>
              <li>Sono a carico di <strong>RAF SERVICE</strong> tutte le spese inerenti la fornitura dei prodotti e dei materiali.</li>
              <li><strong>RAF SERVICE</strong> opera in conformità alle regole stabilite dalla Legge sulla sicurezza sul lavoro.</li>
              <li><strong>RAF SERVICE</strong> dispone di tutta la documentazione tecnica delle attrezzature e dei prodotti utilizzati.</li>
              <li><strong>RAF SERVICE</strong> adotta esclusivamente prodotti con presidio medico chirurgico e prodotti specifici per ogni tipo di settore <strong>secondo la normativa HACCP</strong>.</li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <TitleChecked title="Formazione del personale" />
            <p style={{ marginBottom: '30px'}}>Il personale di <strong>RAF SERVICE</strong> è formato circa il corretto utilizzo delle attrezzature e dei prodotti per la realizzazione dei servizi. E' tenuto, inoltre, ad attenersi scrupolosamente alle seguenti norme comportamentali:</p>
            <p>- indossare sempre la divisa aziendale</p>
            <p>- esporre il tesserino di riconoscimento</p>
            <p>- non prendere visione di documenti e mantenere il segreto d'ufficio</p>
            <p>- non usare altre attrezzature se non quelle per lo svolgimento del servizio</p>
            <p>- non introdurre estranei nei locali di lavoro</p>
            <p>- non asportare nulla dai locali di lavoro</p>
            <p>- non fumare né consumare alimenti nei locali di lavoro</p>
          </Col>
        </Row>
      </Container>
    </PageContainer>
  </Layout>)
}

export default IndexPage
