import styled from "styled-components";
import { theme } from "../../theme";

export const BannerInfoContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  background-color: ${theme.colors.accentedBanner};
  padding: 25px;
  padding-left: 50px;
  position: relative;

  &:before {
    content: '';
    height: calc(100% - 50px);
    position: absolute;
    left: 25px;
    top: 50%;
    transform: translateY(-50%);
    background-color: ${theme.colors.accented};
    width: 2px;
  }
`