import React from 'react'
import PropTypes from "prop-types"
import { BannerInfoContainer } from './styles'


const BannerInfo = ({ children }) => (
  <BannerInfoContainer>
    {children}
  </BannerInfoContainer>
)

BannerInfo.propTypes = {
  chilren: PropTypes.element
}

export default BannerInfo